
import { defineComponent } from "vue";
import { gtagEvents } from "@/shared/gtagEvents";
import { useHead } from "@vueuse/head";

export default defineComponent({
  name: "InquiryTech",
  mounted() {
    this.initMkto();
  },
  unmounted() {
    this.destroyMkto();
  },
  setup() {
    useHead({
      title: "기술 문의 및 지원 요청 - 카카오 i 커넥트 라이브"
    });

    return {};
  },
  methods: {
    sleep: (ms: number) => new Promise(r => setTimeout(r, ms)),

    async initMkto() {
      const mkto = document.createElement("script");
      mkto.innerText =
        'MktoForms2.loadForm("//pages.kakaoenterprise.com", "714-QAI-126", 1996);';
      document.body.append(mkto);

      await this.sleep(300);

      const submitButton = document.querySelector(".mktoButton");
      submitButton?.addEventListener("click", () => {
        gtagEvents("문의 등록하기");
      });
    },

    destroyMkto() {
      const mkto = document.querySelector("#mktoForm_1996");
      if (mkto) {
        mkto.remove();
      }
    }
  }
});
